import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import Button from '../../../../components/Button';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import styles from './styles.module.sass';

const TickItem = ({text, fluid}) => (
    <div className={styles.tickItem}>
        <div className={styles.tickWrap}>
            <Img imgStyle={{ objectFit: 'contain', width: '24px', height: '22px' }}
                objectFit='contain'
                className={styles.imageTick}
                fluid={fluid}/>
        </div>
        <span className={styles.tickText}>{i18n(text)}</span>
    </div>
);

export default () => (
  <StaticQuery
    query={graphql`
        query {
            tick: imageSharp (fluid: { originalName: { eq: "fleet-landing-price-tick.png" }}) {
                fluid(maxWidth: 24, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    `}
    render={({ tick }) => (
        <>
            <SectionWrapper className={clsx(styles.container, styles.pricingTop)}>
                <Grid item container className={styles.titleContainer}>
                    <Typography variant='h2' color='dark' weight='bold'
                        align='center' className={styles.pageTitle}>
                        {i18n('En pris, der er til at forstå')}
                    </Typography>
                    <Typography variant='subtitle2' color='dark' weight='light' 
                        align='center' className={styles.pageSubtitle}>
                        {i18n('Start gratis. Betal, når du vokser')}
                    </Typography>
                    <div className={styles.buttonContainer}>
                        <a href={'https://fleet.obiplus.net/signup'}
                            target='_blank'
                            rel='noreferrer'>
                            <Button className={styles.button}>
                                {i18n('Tilmeld dig på 2 minutter')}
                            </Button>
                        </a>
                    </div>
                </Grid>
            </SectionWrapper>

            <SectionWrapper className={clsx(styles.container, styles.pricingSection)}>
                <Grid item container alignItems={'stretch'} className={styles.tablesWrapper}>
                    
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={styles.pricingWrap}>
                        <div className={styles.pricingTable}>
                            
                            <div className={styles.tableHeader}>
                                Bedre administration af køretøjer<br />for enhver størrelse virksomhed
                            </div>

                            <div className={styles.tableContent}>
                                <Typography variant='h3'
                                    color='dark'
                                    weight='bold'
                                    align='center'
                                    className={styles.columnTitle}>
                                    {i18n('FLEET Core')}
                                </Typography>
                                <Typography variant='subtitle2'
                                    color='dark'
                                    align='center'
                                    className={styles.columnSubtitle}>
                                    {i18n('Dækker det grundlæggende i flådeadministrationen')}
                                </Typography>
                                <div className={styles.tickWrapper}>
                                    <Typography variant='h3'
                                        color='dark'
                                        weight='bold'
                                        align='left'
                                        className={styles.columnTitleSmall}>
                                        {i18n('Hvad er inkluderet?')}
                                    </Typography>
                                    <TickItem fluid={tick.fluid} text={'Adgang til Fleet Core dashboard'}/>
                                    <TickItem fluid={tick.fluid} text={'Gratis opstart og hjælp til at komme i gang'}/>
                                    <TickItem fluid={tick.fluid} text={'Ingen binding'}/>
                                </div>              

                                <div className={clsx(styles.priceHighlight, styles.priceGratis)}>
                                    <span className={styles.footerHighlight}>0 kr.</span><br/>Gratis plan<br/>&nbsp;
                                </div>

                                <div>
                                    <Typography variant='h3'
                                        color='dark'
                                        weight='bold'
                                        align='left'
                                        className={styles.columnTitleSmall}>
                                        {i18n('Funktioner og fordele')}
                                    </Typography>
                                    <ul className={styles.featureList}>
                                        <li>{i18n('Nem og enkel flådeadministration')}</li>
                                        <li>{i18n('Digital kommunikation')}</li>
                                        <li>{i18n('Problemfri service og reparationsoplevelse')}</li>
                                        <li>{i18n('Digital dokumentation')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className={clsx(styles.pricingWrap, styles.rightPricing)}>
                        <div className={styles.pricingTable}>
                            
                            <div className={clsx(styles.tableHeader)}>
                                Automatiser vigtige flådestyringsprocesser<br />ved at frigøre data
                            </div>

                            <Grid container>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className={clsx(styles.tableContent, styles.tableLeft)}>
                                            <Typography variant='h3'
                                                color='dark'
                                                weight='bold'
                                                align='center'
                                                className={styles.columnTitle}>
                                                {i18n('FLEET Care')}
                                            </Typography>
                                            <Typography variant='subtitle2'
                                                color='dark'
                                                align='center'
                                                className={styles.columnSubtitle}>
                                                {i18n('Strømlinet flådevedligeholdelse processer')}
                                            </Typography>
                                            <div className={styles.tickWrapper}>
                                                <Typography variant='h3'
                                                    color='dark'
                                                    weight='bold'
                                                    align='left'
                                                    className={styles.columnTitleSmall}>
                                                    {i18n('Hvad er inkluderet?')}
                                                </Typography>
                                                <TickItem fluid={tick.fluid} text={'Data link til køretøjet'}/>
                                                <TickItem fluid={tick.fluid} text={'Adgang til Fleet Care dashboard'}/>
                                                <TickItem fluid={tick.fluid} text={'Specifik kontaktperson til at hjælpe med at komme i gang'}/>
                                                <TickItem fluid={tick.fluid} text={'Ingen binding'}/>
                                            </div>

                                            <div className={styles.priceHighlight}>
                                                <span className={styles.footerHighlight}>60 kr.</span><br/>pr.bil pr. måned<br/>ekskl. moms
                                            </div>

                                            <div>
                                                <Typography variant='h3'
                                                    color='dark'
                                                    weight='bold'
                                                    align='left'
                                                    className={styles.columnTitleSmall}>
                                                    {i18n('Funktioner og fordele')}
                                                </Typography>
                                                <ul className={styles.featureList}>
                                                    <li>{i18n('Avanceret teknologi til forbindelse af køretøjer')}</li>
                                                    <li>{i18n('GPS-tracking via OBI+ appen')}</li>
                                                    <li>{i18n('Nem og enkel flådeadministration')}</li>
                                                    <li>{i18n('Kørebog til alle medarbejdere')}</li>
                                                    <li>{i18n('Fleet rapporter')}</li>
                                                    <li>{i18n('Digital kommunikation')}</li>
                                                    <li>{i18n('Problemfri service og reparationsoplevelse')}</li>
                                                    <li>{i18n('Digital dokumentation')}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className={styles.tableContent}>
                                            <Typography variant='h3'
                                                color='dark'
                                                weight='bold'
                                                align='center'
                                                className={styles.columnTitle}>
                                                {i18n('FLEET Pro')}
                                            </Typography>
                                            <Typography variant='subtitle2'
                                                color='dark'
                                                align='center'
                                                className={styles.columnSubtitle}>
                                                {i18n('Komplet løsning med øget fleksibilitet')}
                                            </Typography>
                                            <div className={styles.tickWrapper}>
                                                <Typography variant='h3'
                                                    color='dark'
                                                    weight='bold'
                                                    align='left'
                                                    className={styles.columnTitleSmall}>
                                                    {i18n('Hvad er inkluderet?')}
                                                </Typography>
                                                <TickItem fluid={tick.fluid} text={'Data link til køretøjet'}/>
                                                <TickItem fluid={tick.fluid} text={'Adgang til alle Fleet funktioner'}/>
                                                <TickItem fluid={tick.fluid} text={'Specifik kontaktperson til at hjælpe med at komme i gang'}/>
                                                <TickItem fluid={tick.fluid} text={'Ingen binding'}/>
                                            </div>

                                            <div className={styles.priceHighlight}>
                                                <span className={styles.footerHighlight}>100 kr.</span><br/>pr.bil pr. måned<br/>ekskl. moms
                                            </div>

                                            <div>
                                                <Typography variant='h3'
                                                    color='dark'
                                                    weight='bold'
                                                    align='left'
                                                    className={styles.columnTitleSmall}>
                                                    {i18n('I tillæg til Care giver Pro:')}
                                                </Typography>
                                                <ul className={styles.featureList}>
                                                    <li>{i18n('GPS-tracking via Fleet dashboard')}</li>
                                                    <li>{i18n('Automatisk kørselsregistrering')}</li>
                                                    <li>{i18n('Geofencing')}</li>
                                                    <li>{i18n('Ruteplanlægning')}</li>
                                                    <li>{i18n('Kørselsadvarsler')}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                        </div>
                    </Grid>

                </Grid>
            </SectionWrapper>

            <SectionWrapper className={clsx(styles.container, styles.bottomContainer)}>
                <Grid item container className={styles.bottomInner}>
                    <Typography variant='subtitle2' color='dark' weight='light' 
                        align='center' className={styles.pageSubtitle}>
                        {i18n('Start gratis. Betal, når du vokser')}
                    </Typography>
                    <div className={styles.buttonContainer}>
                            <a href={'https://fleet.obiplus.net/signup'}
                                target='_blank'
                                rel='noreferrer'>
                                <Button className={styles.button}>
                                    {i18n('Start nu')}
                                </Button>
                            </a>
                    </div>
                </Grid>
            </SectionWrapper>
        </>
    )}/>
);